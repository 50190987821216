<template>
  <div>
    <Loader v-if="loadingStatus.indexOf('PROFILE') === -1"/>
    <section class="bioBody" v-if="loadingStatus.indexOf('PROFILE') >= 0">
      <header class="bio-header">
        <span
          class="identifier material-icon figure"
        >{{ figureData.body.monument_type | monumentType }}</span>
        <h1 class="title" v-html="figureData.body.title"></h1>
        <h4 class="subtitle" v-html="figureData.body.subtitle"></h4>
        <router-link
          class="button"
          v-if="figureData.body.curriculum_id"
          :to="{ name: 'FigurePage', params: { id: figureData.body.curriculum_id, term: term }}">
          <i class="material-icons">book</i>View Curriculum
        </router-link>
      </header>
      <div class="bio-body">
        <figure
          class="img-container square"
          v-lazy-container="{ selector: 'img' }"
        >
          <img
            class="lazy-image" :data-src="figureData.body.thumbnail_src"
            :data-loading="require('@/assets/img/thumbnail-placeholder.png')"
            />
        </figure>
        <div class="right">
          <h2>Biography</h2>
          <div class="description" v-html="figureData.body.description"></div>
        </div>
      </div>
      <div class="keywords" v-if="figureData.body.keywords">
        <h2 v-if="figureData.body.keywords.length > 0">Keywords</h2>
        <ul class="keywords-list">
          <li v-for="keyword in figureData.body.keywords" :key="keyword.name">
            <router-link :to="{ name: 'SearchResults', query: { term: keyword.name }}">
              {{ keyword.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="searchResults" v-if="relatedData.errorStatus !== 404 && loadingStatus.indexOf('RELATED') >= 0">
      <h2>Artifacts</h2>
      <Loader v-if="relatedData.errorStatus === '' && loadingStatus.indexOf('RELATED') === -1"/>
      <ul class="cards-wrapper"
        v-if="relatedData.errorStatus === '' && loadingStatus.indexOf('RELATED') >= 0">
        <Card
          v-for="(item, index) in relatedData.body"
          v-bind:key="item.id"
          v-bind:item="item"
          v-bind:index="index"
        />
      </ul>
      <router-link
        class="button"
        :to="{ name: 'SearchResults', query: { term: relatedData.header.term }}">
        Search “{{relatedData.header.term}}”
      </router-link>
    </section>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Card from '@/components/Card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BioPage',
  components: {
    Loader,
    Card
  },
  computed: mapGetters([
    'figureData',
    'relatedData',
    'term',
    'visited',
    'loadingStatus'
  ]),
  methods: {
    getBioData: function () {
      this.$store.dispatch('loadmonumentData', { type: 'profile', param: this.$route.params.id })
      this.$store.dispatch('loadmonumentData', { type: 'related', param: this.$route.params.id })
    }
  },
  mounted: function () {
    this.getBioData()
  },
  watch: {
    '$store.state.relatedData': {
      deep: true,
      handler (newVal) {
        // console.log('queryParameter changed', newVal.header.term)
        this.$store.dispatch('setTerm', { term: newVal.header.term })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  section.bioBody .button,
  section.searchResults .button {
    background: transparent;
    color: $siena;
    i { color: $siena; }
    &:hover {
      background: $siena;
      color: $robinblue;
      i { color:$robinblue; }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  section.bioBody {
    width: 100%;
    padding: $margin;
    padding-bottom: calc(#{$margin} * 2);
    background: $translucent-yellow;
    * {
      color: $darkplum;
    }
    header.bio-header {
      width: 100%;
      padding: $margin 0;
      text-align: center;
      h1.title {
        margin: 0.3em 0;
        font-size: 2.5em;
      }
      h4 {
        font-family: "Circular Std", Avenir, Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: 1.25em;
        margin: 0.5em auto;
        max-width: 600px;
      }
      .button:hover {
        color: $yellow;
        i { color: $yellow; }
      }
    }
    .bio-body {
      padding: $margin 0;
      @media (min-width: 768px) {
        display: flex;
        grid-gap: $margin;
        grid-column-gap: $margin;
        figure {
          margin: $margin 0;
        }
      }
      figure {
        margin: 0;
        max-width: 35%;
        img { width: 100%; }
        @media (max-width: 768px) {
          max-width: 100%;
          img { width: 100%; }
        }
      }
      .right {
        text-align: left;
        max-width: 65%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        h2 {
          margin-top: 0;
        }
        .description {
          white-space: pre-wrap;
        }
      }
    }
    ul.keywords-list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      column-gap: 20px;
      max-width: 768px;
      margin: auto;
      flex-wrap: wrap;
      padding: 0px;
      li {
        list-style-type: none;
        font-size: 1.25em;
        font-weight: bold;
        margin: auto 0.125em;
        a:hover {
          color: $siena;
        }
      }
    }
  }
  section.searchResults {
    width: 100%;
    padding: $margin;
    padding-bottom: calc(#{$margin} * 2);
    background: $robinblue;
    .button {
      margin-top: calc(#{$margin} + 0.5em);
    }
    ul.cards-wrapper {
      padding-bottom: $margin;
      li.card.monument[data-type='artifact'] {
        background: white;
      }
      li.card.monument.visited {
        background: $salmon;
      }
    }
  }

// Card styles in src/assets/scss/cards.scss
</style>
